import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Flex } from '@lbc-toolkit/flex';
import OrderCard from 'components/orders/OrderCard';

const propTypes = {
  orders: PropTypes.array.isRequired,
  onCardClick: PropTypes.func
};

const defaultProps = {
  onCardClick: () => {
    /* do nothing */
  }
};

function OrderCards({ orders, onCardClick }) {
  return (
    <Flex inline flow="wrap" justifyContent="flex-start">
      {orders.map(order => (
        <OrderCard order={order} key={order.projectNo} onClick={onCardClick} />
      ))}
    </Flex>
  );
}

OrderCards.propTypes = exact(propTypes);

OrderCards.defaultProps = defaultProps;

export default OrderCards;
