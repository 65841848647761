import React, { useState, useEffect } from 'react';
import { AuthService } from 'services/AuthService';
import { Grid, GridItem } from '@lbc-toolkit/grid';
import Login from 'components/auth/Login';
import OrderPage from 'components/orders/OrderPage';
import { MainNavigation } from '@lbc-toolkit/main-navigation';
import ErrorPage from 'components/common/ErrorPage';

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const authService = new AuthService();
    authService.getUser().then(user => {
      setUser(user);
    });
  }, []);

  const hasAccess =
    user &&
    (user.profile.role.includes('TC-SOD-Internal') ||
      user.profile.role.includes('TC-SOD-User'));

  if (user && !hasAccess) {
    return (
      <ErrorPage message="Please contact your myLiebherr company administrator to gain access." />
    );
  }

  return (
    <>
      {!user && <Login />}
      {user && (
        <>
          <MainNavigation
            tabs={[{}]}
            sticky
            portalName="Sales Order Details"
            avatar={{
              initials: `${user.profile.given_name[0]}${user.profile.family_name[0]}`
            }}
          />
          <Grid
            columns="repeat(12, 1fr)"
            rows="auto 1fr"
            width="100%"
            margin="90px 0 0 0"
            padding="16px"
          >
            <GridItem area="1/2/3/12">
              <OrderPage />
            </GridItem>
          </Grid>
        </>
      )}
    </>
  );
}

export default App;
