import React from 'react';
import PropTypes from 'prop-types';
import { MultiSelect } from '@lbc-toolkit/multiselect';
import { getStatusLabel } from 'tools/statusMethods';

const propTypes = {
  filter: PropTypes.array,
  onUpdateFilter: PropTypes.func
};

const defaultProps = {
  filter: [],
  onUpdateFilter: () => undefined
};

function StatusFilter({ filter, onUpdateFilter }) {
  const options = [...Array(8)].map((_, index) => {
    const statusId = index + 1;
    return { label: getStatusLabel(statusId), value: statusId };
  });

  return (
    <MultiSelect
      selected={filter}
      onSelect={onUpdateFilter}
      options={options}
      label="Sales Order Status"
    />
  );
}

StatusFilter.propTypes = propTypes;

StatusFilter.defaultProps = defaultProps;

export default StatusFilter;
