import React from 'react';
import { AuthService } from 'services/AuthService';

class SilentRefresh extends React.Component {
  componentDidMount() {
    const authService = new AuthService();

    authService
      .silentRefresh()
      .then(response => console.log(response))
      .catch(error => console.error(error));
  }

  render() {
    return <div />;
  }
}

export default SilentRefresh;
