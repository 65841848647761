export const convertIdToStatusObject = id => {
  const label = getStatusLabel(id);
  switch (id) {
    case 1:
      return { label, type: 'white', id: 1 };
    case 2:
      return { label, type: 'white', id: 2 };
    case 3:
      return { label, type: 'greyLight', id: 3 };
    case 4:
      return { label, type: 'greyDarkest', id: 4 };
    case 5:
      return { label, type: 'yellowLight', id: 5 };
    case 6:
      return { label, type: 'yellowStandard', id: 6 };
    case 7:
      return { label, type: 'greenStandard', id: 7 };
    case 8:
      return { label, type: 'greenStandard', id: 8 };
    default:
      return { label, type: '', id: 0 };
  }
};

export const getStatusLabel = id => {
  switch (id) {
    case 1:
      return 'Incoming Order';
    case 2:
      return 'Scheduled Delivery Date';
    case 3:
      return 'Frozen Zone Paint Upcoming';
    case 4:
      return 'Frozen Zone Paint';
    case 5:
      return 'Frozen Zone Upcoming';
    case 6:
      return 'Frozen Zone';
    case 7:
      return 'Shipped';
    case 8:
      return 'Invoiced';
    default:
      return '';
  }
};
