import React from 'react';
import { Flex, FlexItem } from '@lbc-toolkit/flex';
import { Text } from '@lbc-toolkit/text';
import { Spinner } from '@lbc-toolkit/spinner';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';

const propTypes = {
  message: PropTypes.string
};

const defaultProps = {
  message: ''
};

function LoadingSpinner({ message }) {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      height="100%"
      flow="column"
    >
      <FlexItem margin="8px">
        <Spinner spin />
      </FlexItem>
      <FlexItem margin="8px">
        <Text type="h4" color="var(--color-yellow)">
          {message}
        </Text>
      </FlexItem>
    </Flex>
  );
}

LoadingSpinner.propTypes = exact(propTypes);

LoadingSpinner.defaultProps = defaultProps;

export default LoadingSpinner;
