import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Flex, FlexItem } from '@lbc-toolkit/flex';
import { Icon } from '@lbc-toolkit/icon';
import { TCPlannerStatusCard } from '@lbc-toolkit/tcplannerstatuscard';
import { faTruck, faShippingTimed } from '@fortawesome/pro-solid-svg-icons';
import {
  localizeDate,
  generateShippingDateValue,
  generateShippingDateLabel
} from 'tools/dateMethods';
import { convertIdToStatusObject } from 'tools/statusMethods';
import './OrderCard.css';

const propTypes = {
  order: PropTypes.object.isRequired,
  onClick: PropTypes.func
};

const defaultProps = {
  onClick: () => {
    /* do nothing */
  }
};

function OrderCard({ order, onClick }) {
  const status = convertIdToStatusObject(order.status);

  function handleClick() {
    onClick(order);
  }

  const shippingDateClassname =
    order.shippingDateType === 0
      ? 'orderCardTooltip orderCardTooltipWithTruck'
      : 'orderCardTooltip';

  const shippingDateContent = (
    <Flex inline>
      {order.shippingDateType === 0 && (
        <FlexItem margin="0px 6px 0px 0px">
          <Icon src={faTruck} fixedWidth />
        </FlexItem>
      )}
      {order.shippingDateType === 1 && (
        <FlexItem margin="0px 6px 0px 0px">
          <Icon src={faShippingTimed} fixedWidth />
        </FlexItem>
      )}
      <div
        className={shippingDateClassname}
        data-text={`${generateShippingDateLabel(
          order
        )}: ${generateShippingDateValue(order)}`}
      >
        <FlexItem>{localizeDate(order.shippingDate)}</FlexItem>
      </div>
    </Flex>
  );

  return (
    <TCPlannerStatusCard
      margin="12px"
      frozenZone={status?.label === 'Frozen Zone'}
      model={order.craneType}
      date={shippingDateContent}
      statusValue={status.label}
      statusType={status.type}
      topData={[{ label: 'SN', value: order.serialNo }]}
      onClick={handleClick}
    />
  );
}

OrderCard.propTypes = exact(propTypes);

OrderCard.defaultProps = defaultProps;

export default OrderCard;
