import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Button } from '@lbc-toolkit/button';
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-solid-svg-icons';

const propTypes = {
  onLeft: PropTypes.func,
  onRight: PropTypes.func
};

const defaultProps = {
  onLeft: () => undefined,
  onRight: () => undefined
};

function OrderPagingButtons({ onLeft, onRight }) {
  return (
    <div style={{ display: 'flex', float: 'right' }}>
      <Button type="secondary" icon={faAngleLeft} onClick={onLeft} />
      <div style={{ width: '16px' }} />
      <Button type="secondary" icon={faAngleRight} onClick={onRight} />
    </div>
  );
}

OrderPagingButtons.propTypes = exact(propTypes);

OrderPagingButtons.defaultProps = defaultProps;

export default OrderPagingButtons;
