import { performGetRequest } from 'api/request';
import { AuthService } from 'services/AuthService';

const url = process.env.REACT_APP_API_ADDRESS;

const getAccessToken = async () => {
  const authService = new AuthService();
  await authService.login();
  const user = await authService.getUser();

  return user.access_token;
};

class SalesOrderApi {
  static async getOrdersForYear(year) {
    return performGetRequest(
      await getAccessToken(),
      `${url}/SalesOrders/year/${year}`
    );
  }

  static async getOrders() {
    return performGetRequest(await getAccessToken(), `${url}/SalesOrders`);
  }
}

export default SalesOrderApi;
