import App from 'App';
import Callback from 'components/auth/Callback';
import SilentRefresh from 'components/auth/SilentRefresh';
import { Route, BrowserRouter as Router } from 'react-router-dom';

function Root() {
  return (
    <Router>
      <Route exact path="/">
        <App />
      </Route>
      <Route exact path="/signin">
        <Callback />
      </Route>
      <Route exact path="/silent-refresh">
        <SilentRefresh />
      </Route>
    </Router>
  );
}

export default Root;
