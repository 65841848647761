// sort by shipping date, then by status
export const sortOrders = orders => {
  return orders.sort(
    (a, b) =>
      new Date(a.shippingDate) - new Date(b.shippingDate) || a.status - b.status
  );
};

export const filterByYear = (orders, year) => {
  return [...orders].filter(order => {
    return new Date(order.shippingDate).getFullYear() === year;
  });
};

export const filterByCraneTypes = (orders, filterValues) => {
  return orders.filter(order => {
    return filterValues.includes(order.craneType);
  });
};

export const filterByCustomers = (orders, filterValues) => {
  return orders.filter(order => {
    return filterValues.includes(order.customerNo);
  });
};

export const filterByStatus = (orders, filterValues) => {
  return orders.filter(order => {
    return filterValues.includes(order.status);
  });
};
