const options = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  timeZone: 'UTC'
};

export const localizeDate = dateString => {
  if (dateString === null) {
    return '';
  }

  return new Date(dateString).toLocaleDateString('de-DE', options);
};

export const generateShippingDateLabel = order => {
  switch (order.shippingDateType) {
    case 0:
      return 'Shipping Date';
    case 1:
      return 'Planned Shipping Date';
    case 2:
      return 'Est. Shipping Date';
    default:
      return 'Undefined Shipping Date';
  }
};

export const generateShippingDateValue = order => {
  switch (order.shippingDateType) {
    case 0:
    case 1:
      return localizeDate(order.shippingDate);
    case 2:
      return generateDeliveryWeekRange(order.shippingDate);
    default:
      return null;
  }
};

const generateDeliveryWeekRange = dateString => {
  const date = new Date(dateString);
  var begin = new Date(date.setDate(date.getDate() - date.getDay() + 1));
  var end = new Date(date.setDate(date.getDate() - date.getDay() + 5));

  return `${begin.toLocaleDateString(
    'de-DE',
    options
  )} - ${end.toLocaleDateString('de-DE', options)}`;
};
