import { useCallback, useState } from 'react';
import { Grid, GridItem } from '@lbc-toolkit/grid';
import { Flex } from '@lbc-toolkit/flex';
import { Text } from '@lbc-toolkit/text';
import { useOrders } from 'hooks/useOrders';
import OrderOverlay from './OrderOverlay';
import OrderPageHeader from './OrderPageHeader/OrderPageHeader';
import OrderCards from 'components/orders/OrderCards';
import LoadingSpinner from 'components/common/LoadingSpinner';

function OrderPage() {
  const { orders, ordersError, ordersLoading } = useOrders();
  const [displayOrders, setDisplayOrders] = useState([]);

  const [showOverlay, setShowOverlay] = useState(false);
  const [overlayOrder, setOverlayOrder] = useState(null);

  const handleFilterChange = useCallback((filteredOrders) => {
    setDisplayOrders(filteredOrders);
  }, [setDisplayOrders])

  function handleSearch(searchResult) {
    setOverlayOrder(searchResult);
    setShowOverlay(true);
  }

  function handleOrderClick(order) {
    setOverlayOrder(order);
    setShowOverlay(true);
  }

  function handleOverlayClose() {
    setShowOverlay(false);
  }

  return (
    <>
      {!!overlayOrder && (
        <OrderOverlay
          show={showOverlay}
          order={overlayOrder}
          onClose={handleOverlayClose}
        />
      )}
      <Grid columns="repeat(10, 1fr)" rows="auto auto">
        <GridItem area="1/1/1/11" padding="10px 0 10px 0" alignSelf="center">
          <OrderPageHeader
            orders={orders}
            onFilterChange={handleFilterChange}
            onSearch={handleSearch}
          />
        </GridItem>
        <GridItem area="2/1/2/11" margin="10px -12px">
          {ordersLoading && <LoadingSpinner message="Loading Data" />}
          {!!displayOrders && (
            <OrderCards orders={displayOrders} onCardClick={handleOrderClick} />
          )}
          {ordersError && (
            <Flex justifyContent="center">
              <Text type="h3" color="#cf0000">
                No Sales Orders found
              </Text>
            </Flex>
          )}
          {orders.length > 0 &&
            displayOrders.length === 0 &&
            !ordersLoading &&
            !ordersError && (
              <Flex justifyContent="center">
                <Text type="h3" color="var(--color-yellow)">
                  No Data found - please adapt your filter criteria
                </Text>
              </Flex>
            )}
        </GridItem>
      </Grid>
    </>
  );
}

export default OrderPage;
