const jsonResponseType = 'application/json';

const handleErrors = async response => {
  if (!response.ok) {
    const message = await response.json();
    throw Error(`${response.status}: ${message}`);
  }

  return response;
};

export const performGetRequest = (accessToken, url) => {
  return fetch(url, {
    method: 'get',
    headers: {
      Accept: jsonResponseType,
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': jsonResponseType
    },
    mode: 'cors'
  })
    .then(handleErrors)
    .then(result => result.json());
};
