import { Grid, GridItem } from '@lbc-toolkit/grid';
import { Text } from '@lbc-toolkit/text';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';

const propTypes = {
  message: PropTypes.string.isRequired
};

const defaultProps = {
  message: 'There was an error.'
};

function ErrorPage({ message }) {
  return (
    <Grid columns="repeat(12, 1fr)" rows="1fr 1fr 3fr" height="100%">
      <GridItem area="2/3/2/11">
        <Text type="h2" color="var(--color-warning)">
          {message}
        </Text>
      </GridItem>
    </Grid>
  );
}

ErrorPage.propTypes = exact(propTypes);

ErrorPage.defaultProps = defaultProps;

export default ErrorPage;
