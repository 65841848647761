import React from 'react';
import PropTypes from 'prop-types';
import { MultiSelect } from '@lbc-toolkit/multiselect';

const propTypes = {
  craneTypeOptions: PropTypes.array,
  filter: PropTypes.array,
  onUpdateFilter: PropTypes.func
};

const defaultProps = {
  craneTypeOptions: [],
  filter: [],
  onUpdateFilter: () => undefined
};

function CraneTypeFilter({ craneTypeOptions, filter, onUpdateFilter }) {
  const options = [
    ...new Set(craneTypeOptions.sort((a, b) => (a > b) - (a < b)))
  ];
  const sortedOptions = options.map(craneType => ({
    label: craneType,
    value: craneType
  }));

  return (
    <MultiSelect
      options={sortedOptions}
      selected={filter}
      onSelect={onUpdateFilter}
      label="Crane Type"
    />
  );
}

CraneTypeFilter.propTypes = propTypes;

CraneTypeFilter.defaultProps = defaultProps;

export default CraneTypeFilter;
