import { useState } from 'react';
import { useQuery } from 'react-query';
import { sortOrders } from 'tools/orderMethods';
//import SalesOrderApi from 'api/__mocks__/orderApi';
import SalesOrderApi from 'api/orderApi';

export function useOrders() {
  const [orders, setOrders] = useState([]);

  const { isFetching: ordersLoading, isError: ordersError } = useQuery(
    'orders',
    () => SalesOrderApi.getOrders(),
    {
      onSuccess: data => setOrders(sortOrders(data))
    }
  );

  return { ordersLoading, orders, ordersError };
}
