import React from 'react';
import { AuthService } from 'services/AuthService';

class Login extends React.Component {
  authService = new AuthService();

  login = () => {
    this.authService.addAccessTokenExpiredEvent(() => {
      console.log('Access Token expired');
      this.silentRefresh();
    });

    this.authService.login();
  };

  silentRefresh = () => {
    this.authService
      .renewToken()
      .then(response => console.log('Signin Silent'))
      .catch(error => console.error(error));
  };

  componentDidMount() {
    this.login();
  }

  render() {
    return <div />;
  }
}

export default Login;
