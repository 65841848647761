import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@lbc-toolkit/modal';
import { Grid, GridItem } from '@lbc-toolkit/grid';
import StatusFilter from './StatusFilter';
import CustomerFilter from './CustomerFilter';
import CraneTypeFilter from './CraneTypeFilter';
import {
  filterByCraneTypes,
  filterByCustomers,
  filterByStatus,
  filterByYear
} from 'tools/orderMethods';
import { getStatusLabel } from 'tools/statusMethods';

const propTypes = {
  currentYear: PropTypes.number.isRequired,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  orders: PropTypes.array,
  onFilterChange: PropTypes.func,
  filterTags: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      filters: PropTypes.array
    })
  )
};

const defaultProps = {
  show: false,
  onClose: () => undefined,
  orders: [],
  onConfirm: () => undefined,
  onFilterChange: () => undefined,
  filterTags: []
};

function FilterModal({
  show,
  onClose,
  orders,
  currentYear,
  onConfirm,
  onFilterChange,
  filterTags
}) {
  const [applyFilter, setApplyFilter] = useState(false);
  const [customerFilter, setCustomerFilter] = useState([]);
  const [craneTypeFilter, setCraneTypeFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState([]);

  const craneTypeOptions = orders.map(order => {
    return order.craneType;
  });
  const customerNoOptions = orders.map(order => {
    return order.customerNo;
  });

  const fireFilterChange = useCallback(() => {
    let filteredOrders = filterByYear(orders, currentYear);
    if (statusFilter.length > 0) {
      filteredOrders = filterByStatus(filteredOrders, statusFilter);
    }
    if (customerFilter.length > 0) {
      filteredOrders = filterByCustomers(filteredOrders, customerFilter);
    }
    if (craneTypeFilter.length > 0) {
      filteredOrders = filterByCraneTypes(filteredOrders, craneTypeFilter);
    }
    onFilterChange(filteredOrders);
  }, [
    orders,
    currentYear,
    statusFilter,
    customerFilter,
    craneTypeFilter,
    onFilterChange
  ]);

  function handleConfirm() {
    setApplyFilter(true);
    onConfirm([
      {
        type: 'status',
        filters: statusFilter.map(filterValue => {
          return {
            label: getStatusLabel(filterValue),
            value: filterValue
          };
        })
      },
      {
        type: 'customer',
        filters: customerFilter.map(filterValue => {
          const order = orders.find(ord => ord.customerNo === filterValue);
          return {
            label: `${order.customerNo} (${
              order.customerName.substring(0, 15) +
              (order.customerName?.length > 15 ? '...' : '')
            })`,
            value: filterValue
          };
        })
      },
      {
        type: 'craneType',
        filters: craneTypeFilter.map(filterValue => {
          return {
            label: filterValue,
            value: filterValue
          };
        })
      }
    ]);
  }

  useEffect(() => {
    if (applyFilter) {
      fireFilterChange();
      setApplyFilter(false);
    }
  }, [fireFilterChange, setApplyFilter, applyFilter]);

  useEffect(() => {
    setApplyFilter(true);
  }, [currentYear, orders, setApplyFilter]);

  useEffect(() => {
    filterTags.forEach(({ type, filters }) => {
      if (type === 'status') {
        setStatusFilter(filters.map(filter => filter.value));
      }
      if (type === 'customer') {
        const newFilter = filters.map(filter => filter.value);
        setCustomerFilter(newFilter);
      }
      if (type === 'craneType') {
        setCraneTypeFilter(filters.map(filter => filter.value));
      }
    });
    setApplyFilter(true);
  }, [
    filterTags,
    setCustomerFilter,
    setCraneTypeFilter,
    setStatusFilter,
    setApplyFilter
  ]);

  return (
    <Modal
      title="Set Filter"
      show={show}
      height="65%"
      onClose={onClose}
      onCancel={onClose}
      onConfirm={handleConfirm}
    >
      <Grid colums="auto" justifyContent="stretch" gap="36px">
        <GridItem>
          <StatusFilter
            filter={statusFilter}
            onUpdateFilter={setStatusFilter}
          />
        </GridItem>
        <GridItem>
          <CustomerFilter
            customerNoOptions={customerNoOptions}
            orders={orders}
            filter={customerFilter}
            onUpdateFilter={setCustomerFilter}
          />
        </GridItem>
        <GridItem>
          <CraneTypeFilter
            craneTypeOptions={craneTypeOptions}
            filter={craneTypeFilter}
            onUpdateFilter={setCraneTypeFilter}
          />
        </GridItem>
      </Grid>
    </Modal>
  );
}

FilterModal.propTypes = propTypes;

FilterModal.defaultProps = defaultProps;

export default FilterModal;
