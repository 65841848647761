import React from 'react';
import { AuthService } from 'services/AuthService';
import { Redirect } from 'react-router-dom';
import LoadingSpinner from 'components/common/LoadingSpinner';

class Callback extends React.Component {
  state = {
    redirect: false
  };

  componentDidMount() {
    const authService = new AuthService();

    authService
      .redirectCallback()
      .then(response => {
        this.setState({ redirect: true });
      })
      .catch(error => console.error(error));
  }

  render() {
    return (
      <>
        <LoadingSpinner message="Logging In..." />
        {this.state.redirect && <Redirect to="/" />}
      </>
    );
  }
}

export default Callback;
