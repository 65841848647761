import { UserManager } from 'oidc-client';

export class AuthService {
  constructor() {
    const settings = {
      authority: process.env.REACT_APP_OIDC_AUTHORITY,
      client_id: process.env.REACT_APP_OIDC_CLIENT_ID,
      redirect_uri: `${window.location.origin}/signin`,
      silent_redirect_uri: `${window.location.origin}/silent-refresh`,
      post_logout_redirect_uri: window.location.origin,
      response_type: 'code',
      scope: process.env.REACT_APP_OIDC_CLIENT_SCOPE
    };

    this.userManager = new UserManager(settings);
  }

  addAccessTokenExpiredEvent(event) {
    this.userManager.events.addAccessTokenExpired(event);
  }

  getUser() {
    return this.userManager.getUser();
  }

  login() {
    this.userManager.getUser().then(user => {
      if (user === null || user.expired) {
        return this.userManager.signinRedirect();
      }
    });
  }

  renewToken() {
    return this.userManager.signinSilent();
  }

  redirectCallback() {
    return this.userManager.signinRedirectCallback();
  }

  silentRefresh() {
    return this.userManager.signinSilentCallback();
  }

  logout() {
    return this.userManager.signoutRedirect();
  }
}
