import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Searchbox } from '@lbc-toolkit/searchbox';
import { Text } from '@lbc-toolkit/text';
import { Tag } from '@lbc-toolkit/tag';
import { Button } from '@lbc-toolkit/button';
import { Flex, FlexItem } from '@lbc-toolkit/flex';
import { faFilter } from '@fortawesome/pro-solid-svg-icons';
import FilterModal from './FilterModal';
import OrderPagingButtons from './OrderPagingButtons';

const propTypes = {
  orders: PropTypes.array,
  onFilterChange: PropTypes.func,
  onSearch: PropTypes.func
};

const defaultProps = {
  orders: [],
  onFilterChange: () => undefined,
  onSearch: () => undefined
};

function OrderPageHeader({ orders, onFilterChange, onSearch }) {
  const [searchText, setSearchText] = useState('');
  const [searchError, setSearchError] = useState(false);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [filterTags, setFilterTags] = useState([]);
  const [showFilterModal, setShowFilterModal] = useState(false);

  function filterActive() {
    for (let filterTag of filterTags) {
      if (filterTag.filters.length > 0) {
        return true;
      }
    }
    return false;
  }

  const handleFilterChange = useCallback((filteredOrders) => {
    onFilterChange(filteredOrders);
  }, [onFilterChange]);

  function handleConfirmFilter(tags) {
    setShowFilterModal(false);
    setFilterTags(tags);
  }

  function handleSearchChange(value) {
    setSearchText(value);
    setSearchError(false);
  }

  function handleSearch(value) {
    const searchResult = orders.find(order => {
      return order.serialNo === value;
    });

    if (!searchResult) {
      setSearchError(true);
    } else {
      onSearch(searchResult);
    }
  }

  function increaseYear() {
    setCurrentYear(currentYear + 1);
  }

  function decreaseYear() {
    setCurrentYear(currentYear - 1);
  }

  return (
    <>
      <Flex flow="row" justifyContent="space-between" alignItems="center">
        <FlexItem padding="0 16px 0 0">
          <Text type="h3">{currentYear}</Text>
        </FlexItem>
        <FlexItem>
          <Flex flow="row" alignItems="center" justifyContent="flex-end">
            <FilterModal
              show={showFilterModal}
              orders={orders}
              currentYear={currentYear}
              onClose={() => setShowFilterModal(false)}
              onConfirm={handleConfirmFilter}
              onFilterChange={handleFilterChange}
              filterTags={filterTags}
            />
            <Flex flow="row wrap">
              {filterTags.map(({ type, filters }) => {
                return filters.map(tag => {
                  const removeTag = () => {
                    const newTags = [...filterTags];
                    const filterTagIndex = newTags.findIndex(
                      filterTag => filterTag.type === type
                    );
                    const filterIndex =
                      newTags[filterTagIndex].filters.indexOf(tag);
                    newTags[filterTagIndex].filters.splice(filterIndex, 1);
                    setFilterTags(newTags);
                  };

                  return (
                    <FlexItem key={tag.label} padding="8px">
                      <Tag
                        label={tag.label}
                        type="greyStandardCloseable"
                        onClick={removeTag}
                      />
                    </FlexItem>
                  );
                });
              })}
            </Flex>
            {searchError && (
              <FlexItem padding="0 16px">
                <Text color="red" type="pLarge">
                  {searchText} Not Found
                </Text>
              </FlexItem>
            )}
            <Searchbox
              placeholder="Search for serial number"
              width="260px"
              onSearch={handleSearch}
              onEnterPress={handleSearch}
              onChange={handleSearchChange}
              value={searchText}
              isClearable
            />
            <div style={{ width: '16px' }} />
            <Button
              type={filterActive() ? 'primary' : 'secondary'}
              icon={faFilter}
              onClick={() => setShowFilterModal(true)}
            />
            <div style={{ width: '16px' }} />
            <OrderPagingButtons onLeft={decreaseYear} onRight={increaseYear} />
          </Flex>
        </FlexItem>
      </Flex>
    </>
  );
}

OrderPageHeader.propTypes = propTypes;

OrderPageHeader.defaultProps = defaultProps;

export default OrderPageHeader;
