import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Grid, GridItem } from '@lbc-toolkit/grid';
import { List } from '@lbc-toolkit/list';
import { Overlay } from '@lbc-toolkit/overlay';
import { StepNavigation, Step } from '@lbc-toolkit/stepnavigation';
import { Text } from '@lbc-toolkit/text';
import {
  localizeDate,
  generateShippingDateValue,
  generateShippingDateLabel
} from 'tools/dateMethods';
import { convertIdToStatusObject, getStatusLabel } from 'tools/statusMethods';

const propTypes = {
  order: PropTypes.object.isRequired,
  show: PropTypes.bool,
  onClose: PropTypes.func
};

const defaultProps = {
  show: false,
  onClose: () => {
    /* do nothing */
  }
};

function OrderOverlay({ order, show, onClose }) {
  const orderDetails = [
    { label: 'Serial No.', value: order.serialNo },
    { label: 'Order No.', value: order.salesOrderNo },
    {
      label: 'Customer No.',
      value: `${order.customerNo} (${order.customerName})`
    },
    {
      label: generateShippingDateLabel(order),
      value: generateShippingDateValue(order)
    }
  ];

  if (order.frozenZoneDate) {
    orderDetails.push({
      label: 'Frozen Zone',
      value: localizeDate(order.frozenZoneDate)
    });
  }

  if (order.specialPaintStatus) {
    orderDetails.push({
      label: 'Frozen Zone Special Paint',
      value: localizeDate(order.frozenZoneSpecialPaintDate)
    });
  }

  if (order.invoiceDate) {
    orderDetails.push({
      label: 'Invoiced Date',
      value: localizeDate(order.invoiceDate)
    });
  }

  const status = order ? convertIdToStatusObject(order.status) : { id: 0 };

  // Create array for all states to create content of step navigation.
  // If specialPaintStatus is 0 then remove states for specialPaint.
  const states = Array.from({ length: 8 }, (x, i) => i + 1);
  if (!order.specialPaintStatus) {
    states.splice(2, 2);
  }

  const filteredStates = states.filter(
    state => order.frozenZoneDate || ![5, 6].includes(state)
  );

  const stepNavigationContent = filteredStates.map(state => {
    return (
      <Step key={state}>
        <Text type="h6">{getStatusLabel(state)}</Text>
      </Step>
    );
  });

  const currentBullet = filteredStates.findIndex(id => id === status.id);

  return (
    <Overlay show={show} width="33%" status={status} onOutsideClick={onClose}>
      <Grid rows="repeat(7, auto)" columns="100%" height="100%">
        <GridItem padding="32px 64px 32px 64px">
          <Text type="h2">{order?.craneType}</Text>
        </GridItem>
        <GridItem padding="16px 64px 0px 64px">
          <Text type="h4">Details</Text>
        </GridItem>
        <GridItem padding="16px 64px 32px 64px">
          <List data={orderDetails} labelWidth={50} noSeparator />
        </GridItem>
        <GridItem padding="32px 64px 0px 64px" backgroundColor="#f1f1f1">
          <StepNavigation
            direction="vertical"
            currentBullet={currentBullet}
            compact
          >
            {stepNavigationContent}
          </StepNavigation>
        </GridItem>
      </Grid>
    </Overlay>
  );
}

OrderOverlay.propTypes = exact(propTypes);

OrderOverlay.defaultProps = defaultProps;

export default OrderOverlay;
