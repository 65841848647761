import React from 'react';
import PropTypes from 'prop-types';
import { MultiSelect } from '@lbc-toolkit/multiselect';

const propTypes = {
  customerNoOptions: PropTypes.array,
  orders: PropTypes.array,
  filter: PropTypes.array,
  onUpdateFilter: PropTypes.func
};

const defaultProps = {
  customerNoOptions: [],
  orders: [],
  filter: [],
  onUpdateFilter: () => undefined
};

function CustomerFilter({ customerNoOptions, orders, filter, onUpdateFilter }) {
  const options = [
    ...new Set(customerNoOptions.sort((a, b) => (a > b) - (a < b)))
  ];
  const sortedOptions = options.map(customerNo => {
    const order = orders.find(o => o.customerNo === customerNo);
    return {
      label: `${order.customerNo} (${order.customerName})`,
      value: customerNo
    };
  });

  return (
    <MultiSelect
      options={sortedOptions}
      selected={filter}
      onSelect={onUpdateFilter}
      label="Customer-Number"
    />
  );
}

CustomerFilter.propTypes = propTypes;

CustomerFilter.defaultProps = defaultProps;

export default CustomerFilter;
